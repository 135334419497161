<template>
  <div v-if="loaded">
    <div class="d-flex align-items-baseline mb-6">
      <label class="text-dark font-weight-bolder font-size-h4 lh-16">
        {{ $t("meeting_rooms.Meeting_Rooms") }}
      </label>
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
      <span v-if="info.location" class="text-dark-50 font-weight-bolder font-size-sm">
        {{ info.location.name }}
      </span>
    </div>

    <div class="card card-custom">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-4 border-right p-10">
            <div class="list">
              <div class="list-item" :class="{ 'active': selectedView === 0 }">
                <span class="number font-weight-bolder mr-4 font-size-h3">1</span>
                <span class="title">
                  <span class="d-block font-weight-bolder">{{ $t("meeting_room.Booking_Information") }}</span>
                  <span class="text-muted">{{ $t("meeting_room.Check_Booking_Conditions") }}</span>
                </span>
              </div>

              <div v-if="currentPrice !== 0" class="list-item" :class="{ 'active': selectedView === 1 }">
                <span class="number font-weight-bolder mr-4 font-size-h3">2</span>
                <span class="title">
                  <span class="d-block font-weight-bolder">{{ $t("meeting_room.Payment_Method") }}</span>
                  <span class="text-muted">{{ $t("meeting_room.Choose_A_Convenient_Payment_Method") }}</span>
                </span>
              </div>

              <div class="list-item" :class="{ 'active': selectedView === 2 }">
                <span class="number font-weight-bolder mr-4 font-size-h3">{{ currentPrice === 0 ? 2 : 3 }}</span>
                <span class="title">
                  <span class="d-block font-weight-bolder">{{ $t("meeting_room.Meeting_Invitations") }}</span>
                  <span class="text-muted">{{ $t("meeting_room.Invite_Colleagues_Or_Guests") }}</span>
                </span>
              </div>

              <div v-if="isPhoneCheckRequired && currentPrice !== 0" class="list-item" :class="{ 'active': selectedView === 3 }">
                <span class="number font-weight-bolder mr-4 font-size-h3">4</span>
                <span class="title">
                  <span class="d-block font-weight-bolder">{{ $t("meeting_room.Phone_Confirmation") }}</span>
                  <span class="text-muted">{{ $t("meeting_room.Confirm_Phone_Number") }}</span>
                </span>
              </div>

              <div v-if="currentPrice !== 0" class="list-item" :class="{ 'active': selectedView === 4 }">
                <span class="number font-weight-bolder mr-4 font-size-h3">5</span>
                <span class="title">
                  <span class="d-block font-weight-bolder">{{ $t("meeting_room.Payment") }}</span>
                  <span class="text-muted">{{ $t("meeting_room.Pay_For_Your_Booking") }}</span>
                </span>
              </div>
            </div>
          </div>

          <div class="col-8 p-10">
            <div v-if="selectedView === 0">
              <span class="d-block text-muted font-weight-bolder mb-4">{{ $t("meeting_room.Your_Booking") }}:</span>

              <div class="room-details">
                <label class="text-dark text-hover-primary font-weight-bolder font-size-h2 lh-20">
                  {{ info.name }}
                </label>

                <div>
                  <font-awesome-icon icon="map-marker-alt" class="mr-2"/>
                  <span class="text-dark font-weight-bolder">
                    {{ info.name }}
                    <span style="color: #a5a5a5;"> · </span>
                    {{ info.address }}
                    <span style="color: #a5a5a5;"> · </span>
                    {{ $t("meeting_rooms.Floor", {floor: info.floor}) }}
                  </span>
                </div>

                <span class="d-block mt-4">
                  <span
                    class="label label-light-success label-inline font-weight-bolder mr-3"
                    style="border-radius: 10px; font-size: 11px;"
                    v-for="item in info.equipment"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </span>
                </span>

                <div class="d-flex mt-4">
                  <span class="mr-6">
                    <div class="font-size-h2 text-dark">
                      {{ $t("meeting_rooms.Person", {person: info.seats}) }}.
                    </div>
                    <label class="text-muted font-weight-bold">
                      {{ $t("meeting_rooms.Capacity") }}
                    </label>
                  </span>

                  <span>
                    <div v-if="info.rate" class="font-size-h2 text-dark">
                      {{ $t("meeting_rooms.Hour", {hour: info.rate.cost + info.rate.currency.symbol}) }}.
                    </div>
                    <label class="text-muted font-weight-bold">
                      {{ $t("meeting_rooms.The_Cost") }}
                    </label>
                  </span>
                </div>
<!--                :fromTimeStamp="datePeriod.rangePeriod.from"-->
<!--                :toTimeStamp="datePeriod.rangePeriod.to"-->
                <TimeLine class="mt-4" v-if="loaded"
                          :timeStamp="datePeriod.timestamp"
                          :roomData="info"
                          :freeRoomTimeData="freeRoomTime"
                          :isRoom="true"
                          @isValidRange="isValidRange"
                ></TimeLine>
              </div>

              <div class="row mt-8">
                <div class="col-6 mt-4">
                  <span v-if="datePeriod.rangePeriod" class="d-block font-weight-bolder time">{{ setCurrentBookingTime() }}</span>
                  <span v-if="datePeriod.timestamp" class="d-block font-weight-bolder date">{{ setCurrentBookingDate() }}</span>
                  <span v-if="info.rate && currentPrice" class="d-block text-success font-weight-bolder font-size-h1 price">{{ currentPrice + ' ' + info.rate.currency.symbol }}</span>
                  <span v-else-if="currentPrice === 0" class="d-block text-success font-weight-bolder font-size-h1 price">{{ currentPrice }}</span>
                  <span v-else class="d-block text-danger font-weight-bolder font-size-h1 price">{{ $t("meeting_room.Not_Available") }}</span>
                </div>

                <div class="col-6 mt-6">
                  <div class="info">
                    <span class="icon">
                      <font-awesome-icon icon="info" class="icon-sm"/>
                    </span>
                    <span class="font-size-sm">
                      {{ $t("meeting_room.Cancellation_Info_0") }}
                      <strong>{{ setCancelBookingTimeout }}</strong>
                      {{ $t("meeting_room.Cancellation_Info_1") }}
                      <a :href="policy.url" target="_blank"><b>{{ $t("meeting_room.Cancellation_Info_2") }}</b></a>
                      {{ $t("meeting_room.Cancellation_Info_3") }}
                      <a :href="policy.url" target="_blank"><b>{{ $t("meeting_room.Cancellation_Info_4") }}</b></a>
                      <span v-html="$t('meeting_room.Cancellation_Info_5')"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="selectedView === 1">
              <template v-if="(!isOnlinePaymentEnabled && availableUserResources[0] == null && availableUserResources[1] == null && availableResourceIds.length === 0) || (!isOnlinePaymentEnabled && !isCompanyInvoicePaymentEnabled)">
                <p><strong>{{ $t("meeting_room.Online_Payment_Disabled_Message") }}</strong></p>
              </template>
              <template v-else>
                <span class="d-block text-muted font-weight-bolder mb-8">
                {{ $t("meeting_room.Payment_Method_Info") }}
              </span>
                <div class="form-group m-0">
                  <label v-if="isOnlinePaymentEnabled || availableUserResources[0] != null || availableUserResources[1] != null" class="font-weight-bolder">{{ $t("meeting_room.Personal_Account") }}:</label>
                  <div class="row">
                    <div v-if="isOnlinePaymentEnabled" class="col-4 mb-5">
                      <label class="option">
                    <span class="option-control">
                     <span class="radio">
                      <input type="radio" name="m_option_1" :value="'user:' + identity.id + ':cash'" v-model="selectedPayment"/>
                      <span></span>
                     </span>
                    </span>
                        <span class="option-label">
                     <span class="option-head">
                      <span class="option-title font-weight-bolder">
                       {{ $t("meeting_room.Bank_Card") }}
                      </span>
                      <span class="option-focus"></span>
                     </span>
                     <span class="option-body">{{ $t("meeting_room.For_Personal_Account") }}</span>
                    </span>
                      </label>
                    </div>
                    <div v-if="debtBookingSettings.enable_types && (debtBookingSettings.enable_types.includes('1') || debtBookingSettings.enable_types.includes('2'))" class="col-4 mb-5">
                      <label class="option">
                        <span class="option-control">
                         <span class="radio">
                          <input type="radio" name="m_option_1" :value="'user:' + identity.id + ':credit'" v-model="selectedPayment"/>
                          <span></span>
                         </span>
                        </span>
                        <span class="option-label">
                         <span class="option-head">
                          <span class="option-title font-weight-bolder">
                           {{ $t("meeting_room.Booking_On_Credit") }}
                          </span>
                          <span class="option-focus"></span>
                         </span>
                         <span class="option-body">{{ $t("meeting_room.For_Personal_Account") }}</span>
                        </span>
                      </label>
                    </div>
                    <div v-if="checkIsTypeExist('hours')" class="col-4 mb-5">
                      <div v-for="(resource, index) in availableUserResources" :key="index">
                        <label v-if="resource && resource.resource_type === 'hours'" class="option">
                          <span class="option-control">
                           <span class="radio">
                            <input type="radio"
                                   :disabled="resource.total_value <= 0"
                                   name="m_option_1" :value="resource.wallet_id" v-model="selectedPayment"/>
                            <span></span>
                           </span>
                          </span>
                          <span class="option-label">
                           <span class="option-head">
                            <span class="option-title font-weight-bolder"
                                  :class="resource.total_value <= 0 ? 'text-danger' : ''">
                             {{ $t("meeting_room.Balance") }}
                            </span>
                            <span class="option-focus"></span>
                           </span>
                           <span class="option-body">
                             {{ $t("meeting_room.Available") }} {{ Math.floor((resource.total_value - resource.spent_value) / 3600) }} {{ $t("meeting_room.Hours") }}
                           </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div v-if="checkIsTypeExist('deposit')" class="col-4 mb-5">
                      <div v-for="(resource, index) in availableUserResources" :key="index">
                        <label v-if="resource && resource.resource_type === 'deposit'" class="option">
                          <span class="option-control">
                           <span class="radio">
                            <input type="radio"
                                   :disabled="resource.available_value <= 0"
                                   name="m_option_1" :value="resource.wallet_id" v-model="selectedPayment"/>
                            <span></span>
                           </span>
                          </span>
                          <span class="option-label">
                           <span class="option-head">
                            <span class="option-title font-weight-bolder"
                                  :class="resource.available_value <= 0 ? 'text-danger' : ''">
                             {{ coworkingById.id == 2 ? 'SOK Coins' : $t("meeting_room.Certificate") }}
                            </span>
                            <span class="option-focus"></span>
                           </span>
                           <span class="option-body">
                             {{ $t("meeting_room.Available") }} {{ resource.available_value }} {{ coworkingById.id == 2 ? 'Coins' : 'RUB' }}
                           </span>
                          </span>
                        </label>
                      </div>
                    </div>

                    <div v-if="coinsUserBalance >= currentPrice" class="col-4 mb-5">
                      <label class="option">
                        <span class="option-control">
                          <span class="radio">
                            <input type="radio" name="m_option_1" :value="'user:' + identity.id + ':coins'" v-model="selectedPayment" />
                            <span></span>
                          </span>
                        </span>
                        <span class="option-label">
                          <span class="option-head">
                            <span class="option-title font-weight-bolder">Coins</span>
                            <span class="option-focus"></span>
                          </span>
                          <span class="option-body">{{ $t("meeting_room.For_Personal_Account") }}</span>
                        </span>
                      </label>
                    </div>

                  </div>

                  <div v-for="resource in availableResourceIds" :key="resource.id" class="mt-5">
                    <label v-if="isShowCompanyName(resource)" class="font-weight-bolder">{{ $t("meeting_room.Company_Account") }} {{ resource.name }}:</label>
                    <div class="row">
                      <div v-if="isCompanyInvoicePaymentEnabled" class="col-4 mb-5">
                        <label class="option">
                      <span class="option-control">
                       <span class="radio">
                        <input type="radio" name="m_option_1" :value="'company:' + resource.id + ':cash'" v-model="selectedPayment"/>
                        <span></span>
                       </span>
                      </span>
                          <span class="option-label">
                       <span class="option-head">
                        <span class="option-title font-weight-bolder">
                         {{ $t("meeting_room.Bank_Card_Or_Bill") }}
                        </span>
                        <span class="option-focus"></span>
                       </span>
                       <span class="option-body">{{ $t("meeting_room.For_Company") }}</span>
                      </span>
                        </label>
                      </div>
                      <div v-if="debtBookingSettings.enable_types && debtBookingSettings.enable_types.includes('3')" class="col-4 mb-5">
                        <label class="option">
                          <span class="option-control">
                           <span class="radio">
                            <input type="radio" name="m_option_1" :value="'company:' + resource.id + ':credit'" v-model="selectedPayment"/>
                            <span></span>
                           </span>
                          </span>
                          <span class="option-label">
                           <span class="option-head">
                            <span class="option-title font-weight-bolder">
                             {{ $t("meeting_room.Booking_On_Credit") }}
                            </span>
                            <span class="option-focus"></span>
                           </span>
                           <span class="option-body">{{ $t("meeting_room.For_Company") }}</span>
                          </span>
                        </label>
                      </div>
                      <div class="col-4 mb-5" v-for="resDeposit in checkIsDepositsResource(resource)">
                        <label class="option">
                      <span class="option-control">
                       <span class="radio">
                        <input type="radio"
                               :disabled="resDeposit.available_value <= 0"
                               name="m_option_1" :value="resDeposit.wallet_id" v-model="selectedPayment"/>
                        <span></span>
                       </span>
                      </span>
                          <span class="option-label">
                       <span class="option-head">
                        <span class="option-title font-weight-bolder"
                              :class="resDeposit.available_value <= 0 ? 'text-danger' : ''">
                         {{ coworkingById.id == 2 ? 'SOK Coins' : $t("meeting_room.Certificate") }}
                        </span>
                        <span class="option-focus"></span>
                       </span>
                       <span class="option-body">{{ $t("meeting_room.Available") }} {{ resDeposit.available_value }} {{ coworkingById.id == 2 ? 'Coins' : 'RUB' }}</span>
                      </span>
                        </label>
                      </div>
                      <div class="col-4 mb-5" v-for="resResources in checkIsResourcesResource(resource)">
                        <label class="option">
                      <span class="option-control">
                       <span class="radio">
                        <input type="radio"
                               :disabled="resResources.total_value <= 0"
                               name="m_option_1" :value="resResources.wallet_id" v-model="selectedPayment"/>
                        <span></span>
                       </span>
                      </span>
                          <span class="option-label">
                       <span class="option-head">
                        <span class="option-title font-weight-bolder"
                              :class="resResources.total_value <= 0 ? 'text-danger' : ''">
                         {{ $t("meeting_room.Balance") }}
                        </span>
                        <span class="option-focus"></span>
                       </span>
                       <span class="option-body">
                         {{ $t("meeting_room.Available") }} {{ Math.floor((resResources.total_value - resResources.spent_value) / 3600) }} {{ $t("meeting_room.Hours") }}
                       </span>
                      </span>
                        </label>
                      </div>

                      <div v-if="coinsCompanyBalance >= currentPrice" class="col-4 mb-5">
                        <label class="option">
                          <span class="option-control">
                            <span class="radio">
                              <input type="radio" name="m_option_1" :value="'company:' + resource.id + ':coins'" v-model="selectedPayment" />
                              <span></span>
                            </span>
                          </span>
                          <span class="option-label">
                            <span class="option-head">
                              <span class="option-title font-weight-bolder">Coins</span>
                              <span class="option-focus"></span>
                            </span>
                            <span class="option-body">{{ $t("meeting_room.For_Company") }}</span>
                          </span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div v-else-if="selectedView === 2">
              <span class="d-block text-muted font-weight-bolder mb-8">
                {{ $t("meeting_room.Invite_Colleagues_Or_Guests_Info") }}
              </span>

              <div class="row">
                <div class="col-5 border-right">
                  <span class="d-block font-weight-bolder mb-4">{{ $t("meeting_room.Invitations") }}</span>
                  <div class="invite-list" v-if="!inviteList.length">
                    <div class="item mb-4">
                      <span class="name">{{ $t("meeting_room.Emails_For_Invitations") }}</span>
                    </div>
                  </div>
                  <div class="invite-list" v-else>
                    <div class="item mb-4" v-for="(item, index) in inviteList" :key="index">
                      <span class="name">
                        {{ item.email ? item.email : item }}
                        <span v-if="issetPass(item.email ? item.email: item)" :title="$t('meeting_room.Issue_pass')">*</span>
                      </span>
                      <span class="delete text-muted" @click="deleteInvite(item)">{{ $t("meeting_room.Delete") }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-7 pl-10">
                  <b-form-checkbox size="lg" v-model="isInviteesNotificationNeeded" class="mb-10">{{ $t('meeting_room.Send_Invite') }}</b-form-checkbox>

                  <div class="mt-4">{{ $t('meeting_room.Colleague') }}:</div>
                  <vSelect v-model="staff"
                           class="form-control-select mb-4"
                           label='fio'
                           :reduce='name => name.email'
                           :options="peerList"
                           :placeholder="$t('meeting_room.Choose')">
                    <template v-slot:option="option">
                      <template v-if="option.fio === 'all'">{{ $t("meeting_room.All_Colleagues") }}</template>
                      <template v-else>{{ option.fio }} - {{ option.email }}</template>
                    </template>
                    <template #selected-option="{ fio, email }">
                      <strong v-if="fio === 'all'">{{ $t("meeting_room.All_Colleagues") }}</strong>
                      <strong v-else>{{ fio }}</strong>
                    </template>
                  </vSelect>

                  <p><br /></p>
                  <p v-if="isGuest" style="font-weight:bold;">{{ $t('meeting_room.Guest_pass') }}:</p>
                  <label v-if="isGuest">{{ $t("meeting_room.LastName") }}:</label>
                  <div v-if="isGuest" class="input-group">
                    <input class="form-control" :placeholder="$t('meeting_room.LastName')" v-model="lastName">
                  </div>
                  <label v-if="isGuest">{{ $t("meeting_room.FirstName") }}:</label>
                  <div v-if="isGuest" class="input-group">
                    <input class="form-control" :placeholder="$t('meeting_room.FirstName')" v-model="firstName">
                  </div>
                  <label v-if="isGuest">{{ $t('meeting_room.MiddleName') }}:</label>
                  <div v-if="isGuest" class="input-group mb-10">
                    <input class="form-control" :placeholder="$t('meeting_room.MiddleName')" v-model="middleName">
                  </div>

                  <label>{{ $t("meeting_room.Guest_Email") }}:</label>
                  <div class="input-group">
                    <input class="form-control" v-model="selectedGuestColleague" :placeholder="$t('meeting_room.Enter_Guest_Email')">
                    <button class="btn btn-secondary ml-3" @click="addInvite" :disabled="!checkSelectedGuestColleague">{{ $t("meeting_room.Add") }}</button>
                  </div>

                  <!--                  <label class="mt-4">{{ $t("meeting_room.Colleague") }}:</label>-->
<!--                  <select class="form-control" v-model="staff">-->
<!--                    <option :value="[]">{{ $t("meeting_room.Choose") }}</option>-->
<!--                    <option :value="'all'">{{ $t("meeting_room.All_Colleagues") }}</option>-->
<!--                    <option v-for="item in peerList" :value="item" :key="item.id">{{ item['fio'] }} - {{ item.email }}</option>-->
<!--                  </select>-->

                  <div class="info mt-6">
                    <span class="icon">
                      <font-awesome-icon icon="info" class="icon-sm"/>
                    </span>
                    <span class="font-size-sm">
                      {{ $t("meeting_room.Find_Email_Info") }}
                    </span>
                  </div>

                  <label class="mt-6">{{ $t("meeting_room.Comment_On_Booking") }}:</label>
                  <b-form-textarea
                    id="selectMessage"
                    class="form-control"
                    v-model="commentText"
                    :placeholder="$t('meeting_room.Additional_Information')"
                    rows="4"
                    max-rows="6"
                  />
                </div>
              </div>
            </div>

            <div v-else-if="selectedView === 3">
              <span class="d-block text-muted font-weight-bolder mb-6">
                {{ $t("meeting_room.Phone_Confirmation") }}
              </span>

              <template v-if="!phoneByConfirmed">
                <span class="d-block">
                  {{ $t("meeting_room.Phone_Confirmation_Info_1") }}
                </span>
                  <br>
                  <span class="d-block">
                  {{ $t("meeting_room.Phone_Confirmation_Info_2") }}
                </span>
              </template>

              <span v-if="phoneByConfirmed" class="d-block">{{ $t('meeting_room.Phone_Confirmation_Info_3') }}</span>

              <label class="mt-6">{{ $t("meeting_room.Phone_Number") }}:</label>
              <div class="input-group w-400px">
                <vue-tel-input
                  class="form-control"
                  mode="international"
                  v-model="phoneNumber"
                  @validate="validatePhone"
                  :placeholder="$t('meeting_room.Enter_Phone_Number')" />
                <button v-if="isPhoneNumberFound" class="btn btn-secondary ml-3" @click="onCheckValidatePhone" :disabled="!isPhoneNumberValid">{{ $t("meeting_room.Confirm") }}</button>
                <button v-if="!isPhoneNumberFound" class="btn btn-secondary ml-3" @click="onSendCode">{{ $t("meeting_room.Send_Code") }}</button>
              </div>
              <template v-if="!isPhoneNumberFound">
                <label class="mt-6">{{ $t("meeting_room.The_Code") }}:</label>
                <div class="input-group w-150px">
                  <input class="form-control" v-model="code" :placeholder="$t('meeting_room.Enter_Code_From_SMS')">
                </div>
              </template>
            </div>

            <div v-if="selectedView === 4" class="col-lg-8 py-0">
              <div class="row p-10 h-100">
                <div class="col-md-6"></div>
                <div class="col-md-6 text-center d-flex flex-column justify-content-center">
                  <div v-if="isOrderBooked">
                    <div class="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
                      <div class="symbol-label">
                        <font-awesome-icon icon="check" class="text-success icon-md"/>
                      </div>
                    </div>
                    <h3 class="font-weight-bolder mt-5">{{ successMessage }}</h3>
                    <p>{{ $t("meeting_room.Successfully_Booked_Info") }}</p>
                    <router-link to="/dashboard" class="btn btn-block btn-light-primary">{{ $t("meeting_room.Go_To_Main_Page") }}</router-link>
                  </div>

                  <div v-if="!isOrderBooked">
                    <div class="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
                      <div class="symbol-label">
                        <font-awesome-icon icon="ban" class="text-danger icon-md"/>
                      </div>
                    </div>
                    <h3 class="font-weight-bolder mt-5">{{ $t("meeting_room.Error_Booked") }}</h3>
                    <p>{{ $t("meeting_room.Error_Booked_Info") }}</p>
                    <router-link to="/services/rooms" class="btn btn-block btn-primary mt-5">{{ $t("meeting_room.Go_To_Meeting_Rooms_Page") }}</router-link>
                  </div>
                </div>
                <div class="col-md-3"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div v-if="selectedView !== 4 && currentPrice !== 0" class="buttons mt-8">
                  <button class="btn btn-secondary" @click="cancelBooking">{{ $t("meeting_room.Cancel") }}</button>
                  <template v-if="selectedView === 1">
                    <button v-if="isOnlinePaymentEnabled || availableUserResources[0] != null || availableUserResources[1] != null || availableResourceIds.length > 0"
                            :disabled="!selectedPayment" class="btn btn-primary" @click="continueBooking">{{ $t("meeting_room.Next") }}</button>
                  </template>
                  <button v-else-if="selectedView === 2 && !isPhoneCheckRequired" class="btn btn-primary" @click="continueBooking('payment')">{{ $t("meeting_room.Payment") }}</button>
                  <button v-else-if="selectedView === 3" :disabled="!isPhoneConfirmed" class="btn btn-primary" @click="continueBooking">{{ $t("meeting_room.Payment") }}</button>
                  <button v-else :disabled="(!currentPrice && currentPrice !== 0) || !validRange" class="btn btn-primary" @click="continueBooking">{{$t("meeting_room.Next") }}</button>
                </div>
                <!--  Zero payment  -->
                <div v-else-if="currentPrice === 0" class="buttons mt-8">
                  <template v-if="selectedView === 0">
                    <button class="btn btn-secondary" @click="cancelBooking">{{ $t("meeting_room.Cancel") }}</button>
                    <button :disabled="(!currentPrice && currentPrice !== 0) || !validRange" class="btn btn-primary" @click="selectedView = 2">{{ $t("meeting_room.Next") }}</button>
                  </template>
                  <template v-if="selectedView === 2">
                    <button class="btn btn-secondary" @click="selectedView = 0">{{ $t("meeting_room.Cancel") }}</button>
                    <button class="btn btn-primary" @click="continueBooking('zeroСost')">{{ $t("meeting_room.Next") }}</button>
                  </template>
                </div>
                <!--  END: Zero payment  -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showModalMessages" size="md"
             @ok="showModalMessages = false"
             @hidden="showModalMessages = false"
             centered
             hide-header
             hide-footer
             no-close-on-backdrop>
      <b-form class="text-center">
        <p class="m-10">{{ backMessage }}</p>
        <b-button size="sm" variant="primary" @click="showModalMessages = false; backMessage = null"
                  style="margin: 20px auto 0; display: block;">
          {{ $t('meeting_room.Ok') }}</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Api from '@/plugins/api';
import moment from 'moment-timezone'
import TimeLine from '@/components/core/Timeline';
import vSelect from 'vue-select';
import api from "../../../plugins/api";

export default {
  name: 'room',
  components: { TimeLine, vSelect },
  computed: {
    ...mapGetters( `roomsServices`, {
      loaded: `loaded`,
      info: `roomInfo`,
      freeRoomTime: `freeRoomTime`,
      peerList: `peerList`,
      datePeriod: `datePeriod`,
      coinsBalance: 'dashboard/coinsBalance'
    }),
    ...mapGetters( `user`, {
      identity: `identity`,
      phoneByConfirmed: `phoneByConfirmed`,
      availableResourceIds: `availableResourceIds`,
      availableUserResources: `availableUserResources`,
      availableCompaniesResources: `availableCompaniesResources`
    }),
    ...mapGetters({
      coworkingById: 'spaces/coworkingById',
      spaceList: 'spaces/list',
    }),
    setCancelBookingTimeout() {
      let hrs = Math.floor(this.info.cancel_booking_timeout / 3600);
      let mnts = Math.floor(this.info.cancel_booking_timeout / 60);

      let allTime = '';
      if (hrs) allTime += hrs + this.$t("meeting_room.Cancellation_Info_Hours") + " ";
      else if (mnts) allTime += mnts + this.$t("meeting_room.Cancellation_Info_Minutes") + " ";

      return allTime;
    },
    checkSelectedGuestColleague() {
      if (this.staff === 'all') return true;
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(this.selectedGuestColleague);
    },
    coinsCompanyBalance() {
      const coins = this.$store.state.dashboard.coins.company.coins ? this.$store.state.dashboard.coins.company.coins : []
      return coins.reduce((amount, item) => amount + item.remain, 0)
    },
    coinsUserBalance() {
      const coins = this.$store.state.dashboard.coins.user.coins ? this.$store.state.dashboard.coins.user.coins : []
      return coins.reduce((amount, item) => amount + item.remain, 0)
    }
  },
  data() {
    return {
      selectedView: 0,
      currentPrice: 0,
      selectedPayment: null,
      policy: { url: null },
      debtBookingSettings: {},
      showModalMessages: false,
      backMessage: null,
      staff: null,
      selectedGuestColleague: null,
      inviteList: [],
      allEmailsList: [],
      commentText: '',
      phoneNumber: '',
      validRange: true,
      isPhoneConfirmed: false,
      isPhoneNumberValid: false,
      isPhoneNumberFound: true,
      isOnlinePaymentEnabled: true,
      isCompanyInvoicePaymentEnabled: true,
      isPhoneCheckRequired: true,
      isOrderBooked: null,
      isInviteesNotificationNeeded: true,
      code: null,
      params: {
        id: this.$route.params.id,
        // time: this.$route.params.time,
        // from: this.$route.params.from,
        // to: this.$route.params.to,
      },
      successMessage: null,
      isGuest: true,
      firstName: '',
      middleName: '',
      lastName: '',
      passes: []
    }
  },
  mounted() {
    if (this.$route.query.isOrderBooked && this.$route.query.isOrderBooked === 'true') {
      this.selectedView = 4;
      this.isOrderBooked = true;
    }
    this.$store.commit('roomsServices/SelectedDatePeriod', {
      rangePeriod: {
        from: Number(this.$route.query.from),
        to: Number(this.$route.query.to),
      },
      timestamp: Number(this.$route.query.time)
    });
    this.fetch();
    this.GetPeerList();
    this.phoneNumber = this.phoneByConfirmed;
    this.$store.dispatch('dashboard/fetchCoins')
  },
  watch: {
    code(newVal) {
      if (newVal.length === 4) {
        this.onCheckCode();
      }
    },
    staff(newEmail) {
      if (newEmail === 'all') {
        this.selectedGuestColleague = this.$t("meeting_room.All_Colleagues_Emails");
        this.isGuest = false;
      } else {
        this.isGuest = !newEmail;
        this.selectedGuestColleague = newEmail;
        let emails = this.peerList.filter(item => item.email === newEmail);
      }
    }
  },
  methods: {
    ...mapActions(`roomsServices`, [
        `GetPeerList`,
        `LocationInfo`
    ]),
    ...mapActions(`user`, [
      `GetAvailableResources`
    ]),
    moment: function () {
      return moment();
    },
    fetch: function () {
      this.$store.dispatch('roomsServices/GetRoomInfo', this.params.id)
          .then(res => {
            this.$store.dispatch('roomsServices/LocationInfo', res.location_id )
                .then((res) => {
                  this.policy = res.policy;
                  this.debtBookingSettings = res.debt_booking_settings;
                  this.isOnlinePaymentEnabled = res.is_online_payment_enabled;
                  this.isCompanyInvoicePaymentEnabled = res.is_company_invoice_payment_enabled;
                  this.isPhoneCheckRequired = res.is_phone_check_required;

                  // set all colleagues by default
                  if (this.staff === 'all' && this.peerList.length > 0) {
                    this.staff = this.$t("meeting_room.All_Colleagues");
                    this.inviteList.push(this.staff);
                    let emailsArray = this.peerList.filter(item => item.email !== 'all' && item.email !== null).map(item => item.email);
                    this.allEmailsList.push(emailsArray);
                    this.staff = '';
                  } else {
                    this.staff = '';
                  }
                  // END: set all colleagues by default
                });
          });
      if (this.datePeriod && this.datePeriod.timestamp) {
        this.$store.dispatch("roomsServices/GetFreeTime", { ids: this.params.id, from: this.datePeriod.timestamp });
        this.$store.dispatch('user/GetAvailableResources', {
          resource_id: this.params.id,
          start: this.datePeriod.rangePeriod.from,
          end: this.datePeriod.rangePeriod.to
          // start: (+moment.unix(this.datePeriod.rangePeriod.from) - Math.abs(moment.parseZone().utcOffset() * 60) * 1000) / 1000,
          // end: (+moment.unix(this.datePeriod.rangePeriod.to) - Math.abs(moment.parseZone().utcOffset() * 60) * 1000) / 1000,
        });
      }
    },
    checkIsTypeExist(type) {
      let item = this.availableUserResources.filter(item => { return item && item.resource_type === type });
      return item.length > 0;
    },
    checkIsDepositsResource(res) {
      return this.availableCompaniesResources.deposits.filter(item => item.owner.id === res.id);
    },
    checkIsResourcesResource(res) {
      return this.availableCompaniesResources.resources.filter(item => item.owner.id === res.id);
    },
    isValidRange: function (value) {
      this.validRange = value;
    },
    isShowCompanyName: function (resource) {
      if (this.isCompanyInvoicePaymentEnabled || this.debtBookingSettings.enable_types && this.debtBookingSettings.enable_types.includes('3')
          || this.checkIsDepositsResource(resource).length > 0 || this.checkIsResourcesResource(resource).length > 0) {
        return true;
      } else {
        return false;
      }
    },
    setCurrentBookingDate: function () {
      const dt = new Date(this.datePeriod.timestamp * 1000);
      return ('0' + dt.getUTCDate()).slice(-2) + '.' + ('0' + (dt.getUTCMonth() + 1)).slice(-2) + '.' + dt.getUTCFullYear();
    },
    setCurrentBookingTime: function () {
      let dt_from = (new Date(this.datePeriod.rangePeriod.from * 1000));
      let dt_to = (new Date(this.datePeriod.rangePeriod.to * 1000));
      this.setCurrentPrice();
      let str = '';
      str += ('0' + dt_from.getUTCHours()).slice(-2) + ':' + ('0' + dt_from.getUTCMinutes()).slice(-2);
      str += ' — ';
      str += ('0' + dt_to.getUTCHours()).slice(-2) + ':' + ('0' + dt_to.getUTCMinutes()).slice(-2);
      return str === 'aN:aN — aN:aN' ? '' : str;
    },
    setCurrentPrice: async function () {
      await Api.get('/v2/price/meeting-rooms', {
        ids: this.params.id,
        from: this.datePeriod.rangePeriod.from,
        to: this.datePeriod.rangePeriod.to,
      }).then(response => {
        this.currentPrice = response.data[0]['totalCost'];
      });
    },
    addInvite() {
      if(this.isGuest) {
        this.passes[this.selectedGuestColleague] = {
          lastName: this.lastName,
          firstName: this.firstName,
          middleName: this.middleName
        }
        this.lastName = this.firstName = this.middleName = '';
      }
      if (this.staff.length > 0) {
        if (this.staff === 'all') {
          this.staff = this.$t("meeting_room.All_Colleagues");
          this.inviteList = [];
        } else if (this.inviteList.find(item => item === this.$t("meeting_room.All_Colleagues"))) {
          this.deleteInvite(this.$t("meeting_room.All_Colleagues"));
        }
        this.inviteList.push(this.staff);
        this.staff = '';
      } else {
        this.inviteList.push(this.selectedGuestColleague);
        this.selectedGuestColleague = null;
      }
      if (this.inviteList.find(item => item === this.$t("meeting_room.All_Colleagues"))) {
        let emailsArray = this.peerList.filter(item => item.email !== 'all' && item.email !== null).map(item => item.email);
        this.allEmailsList.push(emailsArray);
      } else {
        this.allEmailsList = [];
      }
    },
    deleteInvite(item) {
      let removeIndex = this.inviteList.map(function(el) { return el; }).indexOf(item);
      if (item === this.$t("meeting_room.All_Colleagues")) this.allEmailsList = [];
      else {
        delete this.passes[item];
      }
      this.inviteList.splice(removeIndex, 1);
    },
    continueBooking(step) {
      if (step === 'zeroСost') {
        this.selectedView = 4;
        this.selectedPayment = 'user:' + this.identity.id + ':cash';
      } else {
        step === 'payment' ? this.selectedView = 4 : this.selectedView++;
        if (this.selectedView === 1 && this.availableUserResources.length === 0 && this.availableResourceIds.length === 0) {
          if (this.debtBookingSettings && this.debtBookingSettings.enable_types && this.debtBookingSettings.enable_types.length === 0) {
            this.showModalMessages = true;
            this.backMessage = this.$t("meeting_room.Error_Credit_Booked");
          }
        }
      }

      if (this.selectedView === 4) {
        this.$store.commit('roomsServices/UpdateLoaded', false);
        let paymentParts = this.selectedPayment.split(":")

        const location = this.spaceList.find(item => {
          return item.id === this.info.location_id
        })
        const timezone = location && location.city ? location.city.timezone : 'UTC';
        Api.post('/v2/bookings?expand=order', {
          // phone: this.phoneNumber,
          resource_id: this.$route.params.id,
          // ts_start: (+moment.unix(this.datePeriod.rangePeriod.from) - Math.abs(moment.parseZone().utcOffset() * 60) * 1000) / 1000,
          // ts_end: (+moment.unix(this.datePeriod.rangePeriod.to) - Math.abs(moment.parseZone().utcOffset() * 60) * 1000) / 1000,
          ts_start: (+moment.unix(this.datePeriod.rangePeriod.from) - Math.abs(moment.tz(timezone).parseZone().utcOffset() * 60) * 1000) / 1000,
          ts_end: (+moment.unix(this.datePeriod.rangePeriod.to) - Math.abs(moment.tz(timezone).parseZone().utcOffset() * 60) * 1000) / 1000,
          comment: this.commentText,
          is_invitees_notification_needed: this.isInviteesNotificationNeeded,
          invited: this.allEmailsList.length > 0 ? this.allEmailsList.join(',') : this.inviteList.join(','),
          use_purse: this.selectedPayment,
          // use_purse: 'user:' + this.identity.id,
          // user_id: this.identity.id
        }).then((res) => {

          //Оформить пропуски. Отслеживать удалось ли оформить не требуется
          for(const [email, fio] of Object.entries(this.passes)) {
            Api.post('/v2/guests', {
              location_id: this.info.location.id,
              first_name: fio.firstName,
              last_name: fio.lastName,
              middle_name: fio.middleName,
              arrive_date: (new Date(this.datePeriod.timestamp * 1000)).toISOString().substring(0, 10),
              language: this.$i18n.locale,
              additional_data: 'В переговорную "'+ this.info.name + '"'
            });
          }

          if (res.data.order_id && paymentParts[2] === 'cash' && this.currentPrice !== 0) {
            let data = { id: res.data.order_id };
            api.get('/v2/orders', data).then(res => {

              window.location.href = window.__env.checkout_url.replace(/{guid}/i, res.data[0].guid) + '?roomId=' + this.$route.params.id;
            })
          } else {
            this.$store.commit('roomsServices/UpdateLoaded', true);
            this.isOrderBooked = true;
            if (paymentParts[2] === 'credit') {
              this.successMessage = this.$t("meeting_room.Successfully_Credit_Booked_Info");
            } else {
              this.successMessage = this.$t("meeting_room.Successfully_Booked");
            }
          }
        }).catch(reason => {
          this.$store.commit('roomsServices/UpdateLoaded', true);
          this.isOrderBooked = false;
          if (paymentParts[2] === 'credit') {
            this.showModalMessages = true;
            if (reason.response.data && reason.response.data.error.code === 2) {
              this.backMessage = reason.response.data.error.extra.ts_start[0];
            } else if (reason.response.data && reason.response.data.error.code === 1) {
              this.backMessage = reason.response.data.error.message;
            }
          }
        })
      }
    },
    cancelBooking() {
      this.selectedView > 0 ? this.selectedView-- : this.$router.push('/services/rooms');
    },
    validatePhone(item) {
      if (item.isValid) {
        this.isPhoneNumberValid = true;
      } else {
        this.isPhoneNumberValid = false;
      }
    },
    onCheckValidatePhone() {
      Api.put('/v2/users/phone-by-number', {
        phone: this.phoneNumber
      }).then((res) => {
        if (res.data.is_confirmed) {
          this.isPhoneConfirmed = true;
          this.isPhoneNumberFound = true;
        } else if (!res.data.is_confirmed) {
          this.isPhoneNumberFound = false;
        }
      }).catch(() => {
        this.isPhoneNumberFound = false;
      })
    },
    onSendCode() {
      Api.put('/v2/users/send-phone-code', {
        phone: this.phoneNumber
      }).then((res) => {
        console.log(res)
        this.isPhoneConfirmed = false;
      }).catch(reason => {
        console.log(reason);
      })
    },
    onCheckCode() {
      Api.put('/v2/users/confirm-phone', {
        code: this.code
      }).then((res) => {
        console.log(res)
        this.isPhoneConfirmed = true;
      }).catch(reason => {
        console.log(reason);
      })
    },
    issetPass(email) {
      return (typeof this.passes[email]) === 'object';
    }
  },
  // beforeDestroy() {
  //   this.$store.commit('roomsServices/SelectedDatePeriod', {
  //     rangePeriod: {},
  //     timestamp: null
  //   });
  // }
}
</script>

<style lang="scss" scoped>
  .list {
    &-item {
      display: flex;
      padding: 14px 20px;
      border-radius: 6px;
      margin-bottom: 15px;
      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        width: 40px;
        height: 40px;
        background-color: #F3F6F9;
        border-radius: 6px;
      }
      &.active {
        background: #F3F6F9 0 0 no-repeat padding-box;
        .number {
          background: #000;
          color: #FFF;
        }
      }
    }
  }
  .info {
    display: flex;
    align-items: center;
    background: #F3F6F9 0 0 no-repeat padding-box;
    border-radius: 5px;
    padding: 17px 15px 17px 22px;
    span>a {
      color: #464E5F;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #5a658944 0 0 no-repeat padding-box;
      min-width: 20px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 23px;
    }
  }
  .invite-list {
    .item {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
  }
</style>
